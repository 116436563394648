/* eslint-disable no-cond-assign */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box,
	Input,
	Select,
} from 'Components'
import { FamilyTypes, IFamilys } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import '../styles/index.css'
import { CivilStatusSelector } from 'Components/CivilStatusSelector'
import DateOfBirth from './CustomComponents/DateOfBirth'
import MyComponent from '../student-visa/DocusignCheckEnvelopeId'
import { nanoid } from '@reduxjs/toolkit'
import { observer } from 'mobx-react'
import { jsonArray, keys } from '../../ContratationAnswers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, InputNumber } from 'antd'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}
const PersonalData: React.FC<IProps> = observer(({ closeModal, edit, step, setStep }) => {

	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
		familygroup,
		setFamilyMember,
		familyMembers,
		setCurrentMember,
		setMembersErrors,
		membersErrors,
		displayErrorGlobal,
		setDisplayErrorGlobal
	} = rootStore.familyStore
	const handleChangeMember = (id: any) => {
		const newCurrentMember = familyMembers.find((member) => member.id === id);
		if (newCurrentMember) {
			// Set the newCurrentMember as the currentMember
			setCurrentMember(newCurrentMember);
		}
	}

	const [verificationDocusign, setverificationDocusign] = React.useState(false)
	const [activateDocument, setActivateDocument] = React.useState<string>("")
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const [displayErrors, setDisplayErrors] = React.useState(displayErrorGlobal)
	const [errorDate, setErrorDate] = React.useState(true)
	const [ageForBiometrics, setAgeForBiometrics] = React.useState(false)
	const [showStudyQuesitons, setShowStudyQuesitons] = React.useState(true)
	const [showCAQ, setShowCAQ] = React.useState(false)
	const [hasloa, sethasloa] = React.useState('')
	const { increaseEstep, step: stepLine } = rootStore.formBuilderStore
	const [isWheelChange, setIsWheelChange] = useState(false);

	useEffect(() => {
		validateActiveDocument("biometrics")
	}, [activateDocument])

	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])

	useEffect(() => {
		validateShowBiometrics()
		if (currentMember.type === FamilyTypes.principal && currentMember.purposeVisit === 'Estudios') {
			validateShowCAQ()
		}
	}, [currentMember.age, edit])

	useEffect(() => { }, [open])

	useEffect(() => { }, [emailForUploaders])

	const validateShowCAQ = () => {
		if (
			currentMember.quebec === 'si' ||
			(principalUsed()?.quebec == 'si' &&
				currentMember.type === FamilyTypes.children &&
				currentMember.age >= 5 &&
				currentMember.age <= 17)
		) {
			setShowCAQ(true)
			currentMember.validateCAQquestions = true
			setShowStudyQuesitons(true)
		} else if (
			currentMember.quebec === 'no' ||
			principalUsed()?.quebec == 'no' ||
			currentMember.type != FamilyTypes.children ||
			(currentMember.age < 5 && currentMember.age > 17)
		) {
			setShowCAQ(false)
			currentMember.validateCAQquestions = false
		}

		validateNormalVisitorVisaLogic()
	}
	const validateNormalVisitorVisaLogic = () => {
		if (
			currentMember.type === FamilyTypes.grandchildren ||
			currentMember.type === FamilyTypes.otros
		) {
			handleChange('normalVisitorVisaLogic', true)
			return
		}
		if (
			(principalUsed()?.principalHasPathway === 'si' &&
				currentMember.type === FamilyTypes.conyuge) ||
			(currentMember.type === FamilyTypes.children && currentMember.age < 5)
		) {
			handleChange('normalVisitorVisaLogic', true)
		} else {
			handleChange('normalVisitorVisaLogic', false)
		}
	}

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const validateActiveDocument = (documenttype: string) => {
		if (documenttype = "biometrics") {
			return currentMember.documents[0].active
		}
		else {
			return undefined

		}
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (currentMember.nationality &&
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}


	const emailRegex = new RegExp(
		'^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
	)
	const regex = new RegExp('^[a-zA-Z0-9\\s]+$')

	const checkIfAllowSave = () => {
		if (currentMember.isPreviousClient === "si" && currentMember.validContract !== true && currentMember.type === FamilyTypes.principal) {
			return false
		}
		if (currentMember.appliedBefore === "si" && ((currentMember.validBiometrics === undefined || currentMember.validBiometrics === "") && currentMember.ageForBiometrics === true)) {
			if (currentMember.type === FamilyTypes.principal && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA") {
				return false
			}
		}
		if (new Date() < currentMember.birthday) {
			return false
		}

		if (currentMember.email != currentMember.confirmEmail) {
			return false
		}
		if (!currentMember.appliedBefore) {
			return false
		}

		if (currentMember.appliedBefore === "si" && (/* !currentMember.canadianVisaRejection || */ (!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !currentMember.isPreviousClient)) {
			if (currentMember.type === FamilyTypes.principal) {
				return false
			}
		}

		if (currentMember.appliedBefore === "si" && ((!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true))) {
			if (currentMember.type === FamilyTypes.conyuge && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
				return false
			}

			if (currentMember.type === FamilyTypes.children && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
				return false
			}
		}

		if (currentMember.wantsDependantChildren === "si" && !currentMember.howManyDependantChild) {
			return false
		}

		if (currentMember.wantsDependantChildren === "si" && currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0) {
			return false
		}

		if (((currentMember.appliedBefore === "si"
			&& ageForBiometrics === true
			&& currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
			&& currentMember.type === FamilyTypes.principal)
			||
			((currentMember.type === FamilyTypes.conyuge
				|| currentMember.type === FamilyTypes.children)
				&&
				(ageForBiometrics === true)
				&&
				(ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
				&&
				currentMember.appliedBefore === "si"
			))) {
			if (!currentMember.validBiometrics) {
				return false
			}
		}

		if (!currentMember.email && currentMember.type === FamilyTypes.principal) {
			return false
		}

		if (!currentMember.confirmEmail && currentMember.type === FamilyTypes.principal) {
			return false
		}

		if (currentMember.type !== 'Conyuge o Pareja de hecho' && !currentMember.civilStatus) {
			return false
		}
		if (currentMember.type === 'Solicitante principal' && !currentMember.phoneNumber) {
			return false
		}

		if (currentMember.type === 'Solicitante principal' && !currentMember.countryOfCurrentResidency) {
			return false
		}

		if (currentMember.type === 'Solicitante principal' && !currentMember.address) {
			return false
		}

		//validation visa de estudios fuera de canada
		if (familyMembers[0].purposeVisit === 'Estudios' && currentMember.normalVisitorVisaLogic != true &&
			(currentMember.type === FamilyTypes.principal ||
				currentMember.type === FamilyTypes.children) && !currentMember.hasLoa) {
			return false
		}

		if (!currentMember.name || !currentMember.lastname || !currentMember.birthdayFormatted || !currentMember.countryOfCitizenship) {
			return false
		} else {
			return true
		}
	}

	{/* boton contrac docusign */ }
	const checkErrorFamily = () => {
		const familyErrorInfo: any = []
		familyMembers.map((familyMember: any) => {
			let counter = 0
			if (familyMember.isPreviousClient === "si" && familyMember.validContract !== true && familyMember.type === FamilyTypes.principal) {
				counter++
			}
			if (familyMember.appliedBefore === "si" && ((familyMember.validBiometrics === undefined || familyMember.validBiometrics === "") && familyMember.ageForBiometrics === true)) {
				if (familyMember.type === FamilyTypes.principal && familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA") {
					counter++
					if (familyMember.type === FamilyTypes.conyuge && ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
						counter++
					}

					if (familyMember.type === FamilyTypes.children && ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
						counter++
					}
				}
			}

			if (new Date() < familyMember.birthday) {
				counter++
			}

			if (familyMember.email != familyMember.confirmEmail) {
				counter++
			}
			if (!familyMember.appliedBefore) {
				counter++
			}
			if (familyMember.appliedBefore === "si" && (/* !familyMember.canadianVisaRejection || */ (!familyMember.validBiometrics && familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !familyMember.isPreviousClient)) {
				if (familyMember.type === FamilyTypes.principal) {
					counter++
				}
			}
			if (familyMember.appliedBefore === "si" && ((!familyMember.validBiometrics && familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true))) {
				if (familyMember.type === FamilyTypes.conyuge && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					counter++
				}

				if (familyMember.type === FamilyTypes.children && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					counter++
				}
			}

			if (familyMember.wantsDependantChildren === "si" && !familyMember.howManyDependantChild) {
				counter++
			}

			if (familyMember.wantsDependantChildren === "si" && familyMember.howManyDependantChild && familyMember.howManyDependantChild < 0) {
				counter++
			}

			if (((familyMember.appliedBefore === "si"
				&& ageForBiometrics === true
				&& familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
				&& familyMember.type === FamilyTypes.principal)
				||
				((familyMember.type === FamilyTypes.conyuge
					|| familyMember.type === FamilyTypes.children)
					&&
					(ageForBiometrics === true)
					&&
					(ETA.find((etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
					&&
					familyMember.appliedBefore === "si"
				))) {
				if (!familyMember.validBiometrics) {
					counter++
				}
			}


			if (!familyMember.email && familyMember.type === FamilyTypes.principal) {
				counter++
			}

			if (!familyMember.confirmEmail && familyMember.type === FamilyTypes.principal) {
				counter++
			}

			if (familyMember.type !== 'Conyuge o Pareja de hecho' && !familyMember.civilStatus) {
				counter++
			}
			if (familyMember.type === 'Solicitante principal' && !familyMember.phoneNumber) {
				counter++
			}

			if (familyMember.type === 'Solicitante principal' && !familyMember.countryOfCurrentResidency) {
				counter++
			}

			if (familyMember.type === 'Solicitante principal' && !familyMember.address) {
				counter++
			}

			if (!familyMember.name) {
				counter++
			}
			if (!familyMember.lastname) {
				counter++
			}
			if (!familyMember.birthdayFormatted) {
				counter++
			}
			if (!familyMember.countryOfCitizenship) {
				counter++
			}

			//validation visa de estudios fuera de canada
			if (familyMembers[0].purposeVisit === 'Estudios' && currentMember.normalVisitorVisaLogic != true &&
				(currentMember.type === FamilyTypes.principal ||
					currentMember.type === FamilyTypes.children) && !currentMember.hasLoa) {
				counter++
			}
			familyErrorInfo.push({ id: familyMember.id, errors: counter })
		})
		setMembersErrors(familyErrorInfo)
		return familyErrorInfo
	}
	const popupSubmittionLetter = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p>Immiland recomienda altamente en estos casos incluir un submission letter para contrarestar las razones del rechazo previo. Por lo tanto, este item será incluido en su contrato.</p>' + '<br></br>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}
	const popupAcceptanceLetter = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p>Designated Learning Institution (DLI) por sus siglas traduce Institución de Aprendizaje Designada en Canadá. </p>' +
				'<br><p>Verifique si su LOA indica el numero de DLI: un código que empieza por O seguido de 11 números.</p>' +
				'<p>Una carta de aceptación emitida por un DLI es requisito esencial para solicitar un permiso de estudio.</p>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	};

	const indexFirstConyugue = familyMembers.findIndex((member) => member.type === FamilyTypes.conyuge);
	let indexChildren = 0

	const formPersonal = () => {
		return (
			<div className='flex flex-wrap justify-center'>
				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Tipo de solicitud</Label>
						<Label style={{ color: '#22AD5C' }}>{currentMember.purposeVisit === "Estudios" ? "Estudios" : currentMember.typeOfApplication}</Label>
					</BoxModal>}

				<BoxModal>
					<Label>Nombres: (Como aparece en su pasaporte)</Label>
					<Input
						name='Nombres'
						className='custom-input'
						label='Nombres'
						placeholder='Nombres'
						type='text'
						value={currentMember.name}
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('name', e.target.value) : handleChange('name', undefined) }
						}}
						size='small'
					/>
					{!currentMember.name && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Los nombres son obligatorios</span>}
				</BoxModal>

				<BoxModal>
					<Label>Apellidos: (Como aparece en su pasaporte) </Label>
					<Input
						name='Apellidos'
						className='custom-input'
						label='Apellidos'
						placeholder='Apellidos'
						type='text'
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('lastname', e.target.value) : handleChange('lastname', undefined) }
						}}
						value={currentMember.lastname}
						size='small'
					/>
					{!currentMember.lastname && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Los apellidos son obligatorios
						</span>}
				</BoxModal>

				{currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<Label>Email</Label>
						<Input
							className='custom-input'
							label='Email'
							defaultValues={currentMember.email}
							placeholder='Email'
							type='email'
							id='email'
							name='Email'
							onChange={(e: any) => {
								const emailLowerCase = e.target.value.toLowerCase(); 
								emailLowerCase !== '' && emailLowerCase.match(emailRegex)
								  ? handleChange('email', emailLowerCase)
								  : handleChange('email', undefined);
							  }}
							value={currentMember.email}
							size='small'
							required={true}
						/>
						{!currentMember.email && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su email y revise que este escrito de forma correta</span>}
					</BoxModal>}

				{currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<Label>Confirmar Email</Label>
						<div className='relative'>
							<Input
								className='custom-input'
								label='Email'
								defaultValues={currentMember.confirmEmail}
								placeholder='Email'
								type='email'
								id='email'
								name='email'
								value={currentMember.confirmEmail}
								onChange={(e: any) => {
									const confirmEmailLowerCase = e.target.value.toLowerCase(); 
									confirmEmailLowerCase !== '' && confirmEmailLowerCase.match(emailRegex)
									  ? handleChange('confirmEmail', confirmEmailLowerCase)
									  : handleChange('confirmEmail', undefined);
									confirmEmailLowerCase !== '' && localStorage.setItem("email", confirmEmailLowerCase);
								  }}
								size='small'
								required={true}
							/>
						</div>
						{(!currentMember.confirmEmail || currentMember.confirmEmail !== currentMember.email) && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>El campo escrito en " email" y en "confirmar email" deben ser iguales</span>}
					</BoxModal>}

				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Número de telefono : (+código del país) + (000-000-0000)</Label>
						<Input
							name='Numero de telefono'
							defaultValues={currentMember.phoneNumber}
							className='custom-input'
							label='Numero de telefono'
							placeholder='Numero de telefono'
							type='text'
							value={currentMember.phoneNumber}
							onChange={(e: any) => handleChange('phoneNumber', e.target.value)}
							size='small'
						/>
						{!currentMember.phoneNumber && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su numero de telefono </span>}
					</BoxModal>}

				<DateOfBirth displayErrors={displayErrors} errorDate={errorDate} setErrorDate={setErrorDate} setAgeForBiometrics={setAgeForBiometrics} edit={edit} />

				<BoxModal>
					<Label>País emisor de su pasaporte</Label>
					<div className='relative'>
						<CountrySelector
							handleChange={(e: any) => {
								{ e.target.value != '' ? handleChange('countryOfCitizenship', e.target.value) : handleChange('countryOfCitizenship', undefined) }
							}}
							value={currentMember.countryOfCitizenship || ''}
							label='Pais'
						/>
					</div>
					{!currentMember.countryOfCitizenship && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar país de ciudadanía
						</span>}
				</BoxModal>

				{currentMember.type === 'Solicitante principal' ?
					<BoxModal>
						<Label>País de residencia actual:</Label>
						<div className='relative'>
							<CountrySelector
								handleChange={(e: any) => {
									{ e.target.value != '' ? handleChange('countryOfCurrentResidency', e.target.value) : handleChange('countryOfCurrentResidency', undefined) }
								}}
								value={currentMember.countryOfCurrentResidency || ''}
								label='Pais'
							/>
						</div>
						{!currentMember.countryOfCurrentResidency && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
								Debe seleccionar país de residencia
							</span>}
					</BoxModal> : null}

				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Dirección</Label>
						<Input
							name='Direccion'
							defaultValues={currentMember.address}
							className='custom-input'
							label='Direccion'
							placeholder='Direccion'
							type='text'
							value={currentMember.address}
							onChange={(e: any) => { e.target.value != '' && e.target.value.match(regex) ? handleChange('address', e.target.value) : handleChange('address', undefined) }}
							size='small'
						/>
						{!currentMember.address && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
								Debe indicar su dirección. Este campo no puede tener Caracteres especiales(#~€$%).
							</span>}
					</BoxModal>}

				<BoxModal>
					<Label>Estado Civil</Label>
					<div className='relative'>
						{(currentMember.type !== 'Conyuge o Pareja de hecho') ?
							<CivilStatusSelector
								handleChange={(e: any) => {
									{
										e.target.value != '' ? handleChange('civilStatus', e.target.value) :
											handleChange('civilStatus', undefined)
									}
									if (e.target.value === "Casado" || e.target.value === "Unión marital de hecho") {
										console.log('')
									} else {
										handleChange('wantsAddConyugue', 'no')
										handleChange('ApplyWithSpouse', 'no')
									}


								}}
								value={currentMember.civilStatus || ''}
								label='Pais'
							/> : <p style={{ color: '#22AD5C' }}>{principalUsed()?.civilStatus}</p>}
					</div>
					{(!currentMember.civilStatus && displayErrors === true && currentMember.type === FamilyTypes.principal) &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar su estado civil
						</span>}
					{(!currentMember.civilStatus && displayErrors === true && currentMember.type === FamilyTypes.children) &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar su estado civil
						</span>}


					{currentMember.type === 'Solicitante principal' &&
						familyMembers[0].typeOfApplication !== 'Super visa' &&
						<>
							{
								(familyMembers[0].sponsorship === 'parents' ||
								familyMembers[0].typeOfApplication === "Patrocinio familiar" ||
									familyMembers[0].typeOfApplication === 'Express Entry' ||
									familyMembers[0].typeOfApplication === 'PNP') &&
									(familyMembers[0].civilStatus === "Casado" ||
										familyMembers[0].civilStatus === "Unión marital de hecho") ?
									<BoxModal>
										<Switch
											className="custom-switch"
											checked={familyMembers[0].wantsAddConyugue === "si"}
											onChange={(checked) => {
												if (checked === true) {
													familyMembers[0].wantsAddConyugue = "si";
													familyMembers[0].ApplyWithSpouse = 'si'
													const newMember = {
														id: nanoid(),
														name: '',
														type: FamilyTypes.conyuge,
														lastname: '',
														nationality: '',
														age: -1,
														hasBiometrics: undefined,
														birthday: new Date(),
														birthdayFormatted: moment().format('YYYY-MM-DD'),
														visaType: '',
														groupId: familygroup[0].id,
														monthDifference: -1,
														documents: [
															{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }
														]
													};
													setFamilyMember([...familyMembers, newMember]);
												} else {
													familyMembers[0].wantsAddConyugue = "no";
													familyMembers[0].ApplyWithSpouse = 'no'
													const filteredFamilyMembers = familyMembers.filter((member) => member.type !== FamilyTypes.conyuge);
													setFamilyMember(filteredFamilyMembers);
													setCurrentMember(familyMembers[0]);
												}
											}}
										/>
										<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR CONYUGUE</label>
									</BoxModal> :
									<>
										<BoxModal>
											<Switch
												disabled
												className="custom-switch"
												style={{ backgroundColor: 'gray' }}
											/>
											<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR CONYUGUE</label>
										</BoxModal>
									</>
							}
							{(familyMembers[0].sponsorship === 'spouseOrPartner' ||
							familyMembers[0].typeOfApplication === "Patrocinio familiar"||
								familyMembers[0].typeOfApplication === 'Express Entry' ||
								familyMembers[0].typeOfApplication === 'PNP') ?
								<BoxModal>
									<div className='flex flex-row gap-4 items-center'>
										<div className='flex flex-row gap-2'>
											<Switch
												checked={familyMembers[0].wantsDependantChildren === "si"}
												className="custom-switch"
												onChange={(checked: boolean) => {
													if (checked === true) {
														familyMembers[0].wantsDependantChildren = "si";
													} else {
														familyMembers[0].wantsDependantChildren = "no";
													}
													if (!checked) {
														familyMembers[0].howManyDependantChild = undefined;
														const filteredFamilyMembers = familyMembers.filter(
															(member) => member.type !== FamilyTypes.children
														);
														setFamilyMember(filteredFamilyMembers);
														setCurrentMember(familyMembers[0]);
													}
												}}
											/>
											<label className="inline-block pl-[0.15rem] hover:cursor-pointer">
												AGREGAR HIJOS
											</label>
										</div>
										{familyMembers[0].wantsDependantChildren === "si" &&
											<Input
												style={{ width: '4rem', height: '30px' }}
												className='custom-input no-spinner'
												label='Numero de Hijos dependientes'
												placeholder='0'
												type='number'
												inputMode='numeric'
												min={1}
												value={familyMembers[0].howManyDependantChild}
												onKeyDown={(e: any) => {
													if (e.key === "ArrowUp" || e.key === "ArrowDown") {
														e.preventDefault();
													}
												}}
												onChange={(e: any) => {
													if (e.target.value > -1 && e.target.value < 10) {
														familyMembers[0].howManyDependantChild = e.target.value
														/* { e.target.value > 0 ? handleChange('howManyDependantChild', e.target.value) : handleChange('howManyDependantChild', undefined) } */
														{
															e.target.value != '' &&
																e.target.value > 0 &&
																localStorage.setItem("wantsDependantChildren", e.target.value);
														}
														// Use the functional form of setState to update familyMembers
														if (e.target.value === '') {
															const filteredFamilyMembers = familyMembers.filter(member => member.type !== FamilyTypes.children);
															setFamilyMember(filteredFamilyMembers)
															setCurrentMember(familyMembers[0])
															return
														}
														const updatedFamilyMembers = [...familyMembers];
														for (let i = 0; i < e.target.value; i++) {
															const newMember: IFamilys = {
																id: nanoid(),
																name: '',
																type: FamilyTypes.children,
																lastname: '',
																nationality: '',
																age: -1,
																hasBiometrics: undefined,
																birthday: new Date(),
																birthdayFormatted: moment().format('YYYY-MM-DD'),
																visaType: '',
																groupId: familygroup[0].id,
																monthDifference: -1,
																documents: [{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }]
															}
															updatedFamilyMembers.push(newMember);
														}
														setFamilyMember(updatedFamilyMembers)
													}
												}}
												size='small'
											/>
										}
									</div>
									{currentMember.wantsDependantChildren === "si" && ((currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0) || (!currentMember.howManyDependantChild)) && displayErrors === true &&
										<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe indicar si desea agregar a hijos dependientes</span>}
								</BoxModal>
								:
								<BoxModal>
									<Switch
										disabled
										className="custom-switch"
										style={{ backgroundColor: 'gray' }}
									/>
									<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR HIJOS</label>
								</BoxModal>}
						</>}
				</BoxModal>

				<BoxModal>
					<Label >
						¿Previamente ha aplicado para entrar o permanecer en Canadá?
					</Label>
					<Select
						name='¿Previamente ha aplicado para entrar o permanecer en Canadá?'
						defaultValue={currentMember.appliedBefore}
						className='custom-input'
						labelId='demo-simple-select-label'
						label='¿Previamente ha aplicado para entrar o permanecer en Canadá?'
						value={currentMember.appliedBefore}
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('appliedBefore', e.target.value) : handleChange('appliedBefore', undefined) }
						}}
					>
						<option value=''>Seleccione</option>
						<option value='si'>Si</option>
						<option value='no'>No</option>
					</Select>
					{!currentMember.appliedBefore && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si previamente ha aplicado para entrar o permanecer en Canadá</span>}
				</BoxModal>


				{((currentMember.appliedBefore === "si"
					&& currentMember.ageForBiometrics === true
					&& currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
					&& currentMember.type === FamilyTypes.principal)
					||
					((currentMember.appliedBefore === "si" && currentMember.type === FamilyTypes.conyuge
						|| currentMember.type === FamilyTypes.children)
						&&
						(currentMember.ageForBiometrics === true)
						&&
						ETA.find(
							(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined
						&&
						currentMember.appliedBefore === "si"
					))
					&&
					<BoxModal>
						<Label >
							¿Tiene biométricos válidos?
						</Label>
						<Select
							name='tieneBiometricos'
							defaultValue={currentMember.validBiometrics}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Tiene biometricos'
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('validBiometrics', e.target.value) : handleChange('validBiometrics', undefined) }
								if (e.target.value === "si") {
									currentMember.documents[0].active = true
								} else {
									currentMember.documents[0].active = false
								}

							}}
							value={currentMember.validBiometrics}
						>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{!currentMember.validBiometrics && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si tiene biometricos</span>}
					</BoxModal>}

				{currentMember.appliedBefore === "si" && currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<p className='md:h-2 h8' style={{ fontSize: '0.7rem', fontWeight: 'bold', color: '#22AD5C', marginBottom: '10px' }}>¡Obtenga un descuento!</p>
						<Label
							style={{ fontSize: '0.7rem' }}
						>
							¿Anteriormente ha realizado algún proceso de visado con Immiland?
						</Label>
						<Select
							name='Anteriormente ha realizado algun proceso de visado con Immiland'
							defaultValue={currentMember.isPreviousClient}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Anteriormente ha realizado algun proceso de visado con Immiland'
							value={currentMember.isPreviousClient}
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('isPreviousClient', e.target.value) : handleChange('isPreviousClient', undefined) }
							}}
						>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{!currentMember.isPreviousClient && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si alguna vez le ha sido negada una solicitud por el gobierno de Canadá</span>}

					</BoxModal>}



				{/* boton contrac docusign */}
				{currentMember.isPreviousClient === "si" && currentMember.type === FamilyTypes.principal && <BoxModal className='sm:w-96 px-3 mb-3 md:mb-3 w-full' style={{ marginTop: '12px' }}>
					<MyComponent setVerificationDocusign={setverificationDocusign} />
					{currentMember.isPreviousClient === "si" && !currentMember.validContract && membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>No puede guardar sus datos si su número de contrato no ha sido verificado</span>}
				</BoxModal>
				}

				{!currentMember.validContract && currentMember.type === FamilyTypes.principal && (currentMember.validContract === undefined || currentMember.validContract === false) && <BoxModal >
					<Label>Código de Descuento</Label>
					<Input
						className='custom-input'
						label='Codigo'
						placeholder='Codigo'
						type='text'
						value={currentMember.discountCode}
						onChange={(e: any) => handleChange('discountCode', e.target.value)}
						size='small'
					/>
				</BoxModal>}
				{familyMembers[0].purposeVisit === 'Estudios' && currentMember.normalVisitorVisaLogic != true &&
					(currentMember.type === FamilyTypes.principal ||
						currentMember.type === FamilyTypes.children) ? (
					<>
						{' '}
						<BoxModal>
							<>
								{currentMember.type === FamilyTypes.children ? (
									<>
										{' '}
										<Label>
											<>
												<Label>¿Posee una carta de aceptación (LOA) de un DLI o oferta de empleo en Canada de padres?
													<button type='button' style={{ border: '3px solid #bf3c3c', borderRadius: '100%', marginLeft: '5px', width: '20px', height: '20px', color: '#bf3c3c' }} onClick={popupAcceptanceLetter}><b>i</b></button>
												</Label>
											</>
										</Label>{' '}
									</>
								) : (
									<>
										{' '}
										<Label>¿Posee una carta de aceptación (LOA) de un DLI?
											<button type='button' style={{ border: '3px solid #bf3c3c', borderRadius: '100%', marginLeft: '5px', width: '20px', height: '20px', color: '#bf3c3c' }} onClick={popupAcceptanceLetter}><b>i</b></button>
										</Label>{' '}
									</>
								)}
								<Select
									name='Tiene carta de aceptacion o comprobante de registro de una escuela  canadiense'
									defaultValue={currentMember.hasLoa}
									className='custom-input'
									labelId='demo-simple-select-label'
									label='Procesos en el pasado'
									value={currentMember.hasLoa}
									onChange={(e: any) => {
										{ e.target.value != '' ? handleChange('hasLoa', e.target.value) : handleChange('hasLoa', undefined) }
										{
											e.target.value === 'no'
												? Swal.fire({
													//  title: '<strong>HTML <u>example</u></strong>',
													icon: 'info',
													html:
														'<p style="text-align: justify;">Para solicitar un permiso de estudios, es necesario contar con una carta de aceptación (LOA) de una Institución de Aprendizaje Designada en Canadá. Por lo tanto, para que Immiland pueda procesar su solicitud, debe proporcionar este documento.</p>' +
														'<p style="text-align: justify;">Le ofrecemos asistencia gratuita para el registro y la aceptación en una institución canadiense. Para más información, contáctenos en:  </p>' +
														'<a style="color: red; text-decoration: underline;"  target="_blank" href="mailto:education@immilandcanada.com"> education@immilandcanada.com</a>',

													showCloseButton: true,
													showCancelButton: false,
													focusConfirm: false,
													confirmButtonText: 'Entendido!',
													customClass: {
														confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
													},
													buttonsStyling: false,
												})
												: null
										}
									}}
								>
									<option value=''>Seleccione</option>
									<option value='si'>Si</option>
									<option value='no'>No</option>
								</Select>
								{/* 	<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>{errors.find((error: any) => error === 'Debe subir carta de aceptacion o comprobante de registro de la escuela')?.toString()}</span> */}
								<div>
									{hasloa}</div>
							</>
							{!currentMember.hasLoa && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si tiene carta de aceptacion o comprobante de registro de una escuela
									canadiense</span>}
						</BoxModal>
					</>
				) : null}






			</div>

		)
	}

	const sorted = () => {
		// Primero, filtra los miembros en grupos
		const principalMember = familyMembers.find(member => member.type === FamilyTypes.principal);
		const conyugeMembers = familyMembers.filter(member => member.type === FamilyTypes.conyuge);
		const childrenMembers = familyMembers.filter(member => member.type === FamilyTypes.children);

		// Luego, combina los grupos en el orden deseado
		const sortedFamilyMembers = [];
		if (principalMember) {
			sortedFamilyMembers.push(principalMember);
		}
		if (conyugeMembers.length > 0) {
			console.log("conyugue member")
			sortedFamilyMembers.push(...conyugeMembers);
			console.log(JSON.stringify(sortedFamilyMembers))
		}
		if (childrenMembers.length > 0) {
			sortedFamilyMembers.push(...childrenMembers);
		}

		return sortedFamilyMembers
	}

	return (
		<div>
			<Box>
				<>
					<form className='w-full' id='fromEl' style={{ fontFamily: 'Lato, sans-serif' }}>
						<div className='flex flex-wrap -mx-3 mb-6'>
							<div className='mx-16'>
								<div>
									{sorted() && sorted().length > 0 && sorted().map((member, index) => {
										{ member.type === FamilyTypes.children && indexChildren++ }
										const memberError = membersErrors.find((error: any) => error.id === member.id);
										if (memberError && memberError.errors !== undefined) {
											return (
												<div>
													<div className='form-tab-continer'>
														{memberError.errors ? <>
															<div key={member.id} className='w-full'>
																<button className='custom-input-error'
																	onClick={(e) => {
																		e.preventDefault();
																		handleChangeMember(member.id);
																		if (memberError.errors > 0) {
																			setDisplayErrorGlobal(true);
																		} else {
																			setDisplayErrorGlobal(false);
																		}
																	}}
																>
																	<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																	<div className='flex flex-row gap-4'>
																		<div className='error-flag-container'>
																			<div className='error-flag-simbol'>
																				<div>x</div>
																			</div>
																			<p> Tiene {memberError.errors} errores</p>
																		</div>
																		<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																	</div>
																</button>
															</div></> :
															<>
																<div key={member.id} className='w-full'>
																	<button className='custom-input-success'
																		onClick={(e) => {
																			e.preventDefault();
																			handleChangeMember(member.id);
																			if (memberError.errors > 0) {
																				setDisplayErrorGlobal(true);
																			} else {
																				setDisplayErrorGlobal(false);
																			}
																		}}
																	>
																		<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																		<div className='flex flex-row gap-4'>
																			<div className='success-flag-container'>
																				<div className='success-flag-simbol'>
																					<div>
																						<FontAwesomeIcon style={{ fontSize: '10px', color: 'white' }} icon={faCheck} />
																					</div>
																				</div>
																				<p> Tiene datos correctos</p>
																			</div>
																			<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																		</div>

																	</button>
																</div>
															</>}

														{member.id === currentMember.id &&
															formPersonal()}
													</div>
												</div>
											);
										} else {
											return (
												<div>
													<div className='form-tab-continer'>

														<button className='custom-input-button' onClick={(e) => {
															e.preventDefault();
															handleChangeMember(member.id);
														}} key={member.id} >
															<div className='flex flex-row justify-between w-full'>
																<p>{member.type} {member.type === FamilyTypes.children && `#${indexChildren} `}</p>
																<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
															</div>
														</button>

														{member.id === currentMember.id &&
															formPersonal()}
													</div>
												</div>
											);
										}
									})}
								</div>
								{membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 ? <div className='errors-main-flag'>
									<div className='error-flag-simbol'>
										<div>x</div>
									</div>
									<div>
										<p style={{ color: '#BC1C21', fontSize: '0.9rem' }}>{`tiene ${membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0)} errores en su formulario`}</p>
										<p style={{ color: '#F56060', fontSize: '0.7rem' }}>Por favor, revise cada categoria y corrijalos para poder continuar</p>
									</div>
								</div> : null}
								<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
									{edit === false ?
										<Button
											type='button'
											className='backbutton-disc2'
											onClick={() => {
												if (familyMembers[0].typeOfApplication === 'PNP') {
													setCurrentMember(familyMembers[0])
													setStep(7)
												} else {
													setCurrentMember(familyMembers[0])
													setStep(step - 1)
												}
											}} >
											Atras
										</Button> : null
									}
									<Button
										type='button' onClick={() => {
											console.log('hola andrea', familyMembers)
											window.scrollTo(0, 0)
											checkErrorFamily()
											if (checkIfAllowSave() === true && (checkErrorFamily().some((item: any) => item.errors > 0) === false)) {
												jsonArray.length = 0
												handleChange('principal', true)
												handleChange('valid', true)

												for (const familyMem of familyMembers) {
													const answers: any = {
													}
													for (const key in keys) {
														answers[keys[key]] = familyMem[key as keyof typeof familyMem]
													}
													jsonArray.push(answers)
													familyMem.answers = []
													familyMem.answers[0] = answers
												}
												closeModal()
												setDisplayErrors(false)
												increaseEstep()
											} else {
												checkErrorFamily()
												setDisplayErrors(true)
											}
										}} className='startbutton-disc2'>
										Guardar
									</Button>
								</div>
								{/* end page 5 */}
							</div>
						</div>
					</form >
				</>
			</Box >
		</div >
	)
})
export default PersonalData

